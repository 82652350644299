.content-footer {
  position: sticky;
  bottom: 0;
  padding: 8px;
  background-color: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  width: 100%;
  & > button, & > a, & > div {
    margin-right: 8px ;
  }
  & > button, & > a, & > div, & > a > button  {
    font-weight: 500;
  }
}