@import '../../utils/stylesVariables';

.time-aggregator {
  position: relative;
  width: 139px;
  height: 40px;
  margin-right: 20px;
  display: inline-block;
  .ant-select {
    height: 100%;
    width: 100%;

    &-arrow {
      position: absolute;
      top: 0;
      right: 15px;
      height: 40px;
      margin-top: initial;
      display: flex;
      vertical-align: initial;
      align-items: center;
      i, span.anticon {
        color: black;
      }
    }

    &-selector {
      display: flex;
      justify-content: space-between;
      border: 0 !important;
      height: 100% !important;
      border-radius: 20px !important;
      margin-right: 0;
      padding: 0 16px !important;
      .ant-select-selection-item {
        line-height: 40px;
        color: black;
      }
      &__rendered {
        color: black;
        height: 100%;
        line-height: 40px;
        margin: 0;
        padding: 0;
      }

      &:active, &:focus {
        box-shadow: none;
        outline: 0;
      }
    }
  }
  &-dropdown {
    border-radius: 10px;
    .ant-select-dropdown-menu-item-group-title {
      margin-left: 7px;
      color: $primary300;
      font-size: 11px;
      font-weight: bold;
    }
    .ant-select-dropdown-menu-item {
      padding: 8px 12px;
      color: black;
      &-disabled {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}