.loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  i, span.anticon {
    height: 75%;
    font-size: 5em;
    line-height: 5em;
  }
}
.login-page-loader {
  i, span.anticon {
    font-size: 8em;
  }
}
.change-user-loader {
  position: absolute;
  z-index: 2;
  background: rgba(255, 255, 255, 0.75);
  i, span.anticon {
    position: absolute;
    top: 30%;
    left: 35%;
  }
}