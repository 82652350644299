@import './../utils/stylesVariables';

.red-color {
    color: $red;
}

.btn {
    height: 40px;
    margin: 10px 0 20px;
    padding: 0 25px;
    display: inline-block;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    line-height: 40px;
    background-color: $red;
    color: $white;
    &:hover {
        box-shadow: 3px 4px 5px 1px rgba(0, 0, 0, 0.1);
    }
    &.grey {
        background-color: grey;
    }
    &.white {
        background-color: $white !important;
        color: $primary !important;
    }
    &.disabled {
        cursor: not-allowed;
        background-color: grey;
    }
}

.adding-form {
    .error {
        height: 21px;
        margin: 10px 0 10px 160px;
        color: $red;
    }
    .item {
        &:first-child {
            margin-top: 30px;
            .ant-select {
                width: 520px;
                margin-right: 20px;
            }
        }
        label {
            width: 225px;
            display: inline-block;
            line-height: 40px;
        }
        input {
            width: 520px;
            height: 40px;
            margin-right: 20px;
        }
        input.free-rounds-credentials-input {
            width: 200px;
        }
        .ant-select-selection {
            width: 520px;
            height: 40px;
            .ant-select-selection__rendered {
                height: 40px;
                line-height: 40px;
            }
        }
        textarea + .error {
            margin-left: 0;
        }
        h3 {
            height: 25px;
            margin: 50px 0 20px;
            font-size: 25px;
            font-weight: bold;
        }
        &.switcher {
            margin-bottom: 30px;
        }
    }
    textarea {
        background-color: $primary;
        color: $white;
    }
    .btn-container {
        position: relative;
        .btn {
            position: absolute;
            left: 0;
            min-width: 100px;
            height: 40px;
            padding: 0 20px;
            display: block;
            overflow: hidden;
            text-align: center;
            &:nth-child(2) {
                left: 120px;
            }
        }
        .cancel {
            background-color: $white;
            color: $primary;
        }
    }
}

.cp-table {
    width: 100%;
    &__wrapper {
        .content-item {
            padding: 8px 0;
        }
    }
    tr {
        border-bottom: 1px solid $grey;
    }
    th {
        text-wrap: none;
        padding: 8px 24px;
        .cell-inner {
            display: flex;
            height: 28px;
            .sorter {
                padding-top: 0;
            }
            &:hover > .sorter {
                opacity: 1;
            }
        }
    }
    &__cell {
        padding: 8px 24px;
        &__number {
            width: 10%;
        }
        &__name {
            width: 25%;
        }
        &__code {
            width: 10%;
        }
        &__action {
            width: 10%;
        }
    }
    &.clickable {
        tbody {
            tr {
                cursor: pointer;
                &:hover {
                    background-color: $grey;
                    .cp-table__cell__name {
                        color: $red;
                        text-decoration: underline;
                    }
                    button {
                        transition: all 0s;
                        background-color: $grey;
                        span {
                            transition: all 0s;
                            background-color: $grey;
                        }
                    }
                }
                button {
                    transition: all 0s;
                    background-color: $white;
                    span {
                        transition: all 0s;
                        background-color: $white;
                    }
                }
            }
        }
    }
}

.account-status {
    padding: 4px 12px;
    border-radius: 15px;
    text-transform: uppercase;
    color: #2eb165;
    background-color: #d5f5e3;
    font-size: 12px;
    font-weight: bold;
    &.blocked {
        color: #d5263a;
        background-color: #fae6e8;
    }
}

.cp-table__footer {
    display: flex;
    align-items: center;
    height: 80px;
    .btn {
        margin: 0;
    }
    .cp-table-footer__item {
        margin-left: 24px;
    }
}

.breadcrumbs-separator {
    margin-right: 5px;
}

.default-currency-picker {
    .item {
        margin-bottom: 16px;
    }
}
