@media only screen
and (max-width : 320px) {
  .plates-container {
    a {
      display:block;
      width: 100%;
    }
  }
}

@media only screen
and (min-width : 321px) {
  .plates-container {
    a {
      display:block;
      width: 100%;
    }
  }
}

@media only screen
and (max-width : 768px)
and (orientation: landscape){

}

@media only screen
and (min-width : 768px)
and (max-width : 1024px) {

}

@media only screen
and (min-width : 768px)
and (max-width : 1024px)
and (orientation: landscape){

}

@media only screen
and (min-width : 1025px) {
  .plates-container {
    a {
      display:block;
      width: 260px;
      margin-right: 10px;
    }
  }
}

@media only screen
and (min-width : 1824px) {

}