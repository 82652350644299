@import '../../../../utils/stylesVariables';

.cp-operator-game-configs {
  .currency-item {
    position: relative;
    margin-bottom: 16px;
    padding: 16px 24px;
    border-radius: 10px;
    background-color: $white;
    h3 {
      position: relative;
      margin: 0;
      cursor: pointer;
      font-weight: bold;
    }
    .currency-content {
      max-height: 1000000px;
      transition: max-height 0.1s ease-in-out;
      overflow: hidden;
      &.collapsed {
        max-height: 0;
      }
    }
    .arrow {
      position: absolute;
      top: 16px;
      right: 24px;
      i, span.anticon {
        font-size: 12px;
        svg {
          transition: .3s ease-in;
        }
      }
    }
    .gray {
      color: $primary300;
    }
  }
  .error {
    height: 21px;
    color: $red;
  }
  textarea {
    margin-top: 15px;
    background-color: $primary;
    color: lightgray;
  }
}

.cp-operator-upper-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  .btn {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 8px;
  }
}

.cp-operator-footer {
  //position: absolute;
  //bottom: 0;
  background-color: $grey;
  padding: 8px 16px;
  width: 100%;
  .btn {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 8px;
  }
}

.close-button {
  color: $primary
}