@media only screen
and (max-width : 320px) {
  .login-page {
    flex-direction: column;
    &__logo-container {
      height: 20%;
    }
    &__form {
      width: 290px;
    }
    &__copyright {
      display: none;
      &__mobile {
        color: #bec0c4;
        font-size: 10px;
        margin-top: -25px;
        .copyright__text {
          display: flex;
          justify-content: center;
        }
      }
    }
    &__logo-container {
      width: 59px;
      height: 86px;

    }
    &__container__red {
      width: 100%;
      height: 20%;
    }
    &__container__gray {
      width: 100%;
      justify-content: flex-start;
      margin-top: 16px;
    }
  }
}


@media only screen
and (min-width : 321px) {
  .login-page {
    flex-direction: column;
    &__form {
      width: 290px;
    }
    &__copyright {
      display: none;
      &__mobile {
        color: #bec0c4;
        font-size: 10px;
        margin-top: -25px;
        .copyright__text {
          display: flex;
          justify-content: center;
        }
       }
    }
    &__logo-container {
      width: 59px;
      height: 86px;
    }
    &__container__red {
      width: 100%;
      height: 20%;
    }
    &__container__gray {
      width: 100%;
      justify-content: flex-start;
    }
  }
}

@media only screen
and (max-width : 812px)
and (orientation: landscape){
  .login-page {
    &__form {
      width: 290px;
    }
    &__container__red {
      height: 0;
    }
    &__container__gray {
      width: 100%;
      justify-content: center;
    }
  }
}

@media only screen
and (min-width : 813px)
and (max-width : 1024px) {
  .login-page {
    flex-direction: column;
    &__logo-container {
      width: 86px;
      height: 127px;
    }
    &__container__red {
      width: 100%;
      height: 25%;
    }
    &__container__gray {
      width: 100%;
      justify-content: center;
      margin-top: 0;
    }
  }
}

@media only screen
and (min-width : 813px)
and (max-width : 1024px)
and (orientation: landscape){
  .login-page {
    &__form {
      width: 290px;
    }
    &__container__red {
      height: 25%;
    }
    &__container__gray {
      width: 100%;
      justify-content: center;
      margin-top: 0;
    }
  }
}

@media only screen
and (min-width : 1025px) {
  .login-page {
    flex-direction: row;
    &__logo-container {
      width: 232px;
      height: 336px;
    }
    &__form {
      width: 350px;
    }
    &__copyright {
      display: block;
      font-size: 20px;
      color: white;
      &__mobile {
        display: none;
      }
    }
    &__container__red {
      width: 50%;
      height: 100%;
    }
    &__container__gray {
      width: 50%;
      justify-content: center;
      margin-top: 0;
    }
  }
}

@media only screen
and (min-width : 1824px) {

}