@import '../../utils/stylesVariables';

.search-result__wrapper {
  display: grid;
  column-gap: 8px;
  row-gap: 8px;
}
.player-card {
  height: 64px;
  border-radius: 5px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  transition: .3s;
  &:hover {
    box-shadow: 0 2px 4px 0 $primary300;
  }
  &:active {
    background-color: $grey;
    box-shadow: 0 1px 1px 0 $primary300;
  }
  &__icon {
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    height: 46px;
    min-width: 46px;
    border-radius: 23px;
    margin-left: 16px;
  }
  p {
    margin: 0;
    margin-left: 16px;
    color: #4A4A4A;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 500;
    &.small-font {
      font-size: 13px;
    }
  }
}

@import "SearchResultsMobile";