@import '../../utils/stylesVariables';

.cp {
  &-operator-actions {
    padding: 4px;
    border-radius: 18px;
    background-color: $red;
    color: $white;
    font-size: 24px;
    line-height: 24px;
    &:hover, &:focus {
      border-color: $lighterRed;
      background-color: $lighterRed;
      color: $white;
      svg {
        color: $white;
      }
    }
  }
}

.actionsPopup.ant-dropdown-menu {
  position: static;
  width: inherit;
}