@import '../../../utils/stylesVariables';

.game-default-config {
  textarea {
    background-color: $primary;
    color: lightgray;
  }
}

.game_config-footer {
  display: flex;
  & > button {
    margin-right: 8px;
  }
  & > a {
    margin-right: 8px;
  }
}
