@media only screen
and (max-width : 320px) {
  .content {
    &-title {
      display: block;
      font-size: 28px;
      position: relative;
      top: 0;
      left: 0;
    }
  }
  .title-span {
    display: block;
  }
}

@media only screen
and (min-width : 321px) {

}

@media only screen
and (max-width : 768px)
and (orientation: landscape){

}

@media only screen
and (min-width : 768px)
and (max-width : 1024px) {
  .content {
    padding-left: 16px;
    padding-right: 16px;
    .content-item {
      padding: 8px 16px;
      &__title {
        line-height: 14px;
        font-size: 12px;
      }
      &__collapsing-button {
        width: 21px;
        height: 15px;
      }
    }
  }
}

@media only screen
and (min-width : 768px)
and (max-width : 1100px) {
  main.players-report {
    .content-item {
      &__wrapper.player-profile {
        max-height: calc(100% - 110px);
      }
    }
  }
}

@media only screen
and (min-width : 1025px) {

}

@media only screen
and (min-width : 1824px) {

}