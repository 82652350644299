.unfinished-rounds {
  &__header-tools {
    display: flex;
    margin-bottom: 8px;
    .custom-button {
      margin-left: auto;
    }
  }
}

.unfinished-rounds-finish-button {
  margin-bottom: 8px;
}