@media only screen
and (max-width : 320px) {
  .reports-plate {
    width: 100%;
    height: 80px;
    margin-right: 0;
    align-items: center;
  }
}

@media only screen
and (min-width : 321px) {
  .reports-plate {
    width: 100%;
    height: 80px;
    margin-right: 0;
    align-items: center;
  }
}

@media only screen
and (max-width : 768px)
and (orientation: landscape){

}

@media only screen
and (min-width : 768px)
and (max-width : 1024px) {
}

@media only screen
and (min-width : 768px)
and (max-width : 1024px)
and (orientation: landscape){

}

@media only screen
and (min-width : 1025px) {
  .reports-plate {
    width: 260px;
    height: 112px;
    margin-right: 10px;
    align-items: flex-end;
  }
}

@media only screen
and (min-width : 1824px) {

}