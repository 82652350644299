@media only screen
and (max-width : 767px) {
  .main-header {
    padding: 16px 16px 8px 16px;
  }
  .header-search {
    width: 300px;
  }
}
@media only screen
and (min-width : 768px)
and (max-width : 1024px) {
  .main-header {
    padding: 16px 16px 8px 16px;
  }
  .header-search {
    width: 300px;
  }
}

@media only screen
and (min-width : 1025px) {
  .main-header {
    padding: 16px 16px 8px;
  }
  .header-search {
    width: 400px;
  }
}
