@import '../../utils/stylesVariables';

$firstChartColor: #00d9a0;
$secondChartColor: #3579ff;
$thirdChartColor: #ffb600;
$fourthChartColor: #cf0920;
$fifthChartColor: #1c366a;
$sixthChartColor: #ff837d;
$seventhChartColor: #ff7700;

.chart {
    position: relative;
    .axis-names {
        div {
            position: absolute;
            top: 50%;
            width: 200px;
            text-align: center;
            color: #caccd0;
            &:nth-child(1) {
                transform: rotate(-90deg) translate(-50%, 0%);
                transform-origin: 0 0;
            }
            &:nth-child(2) {
                right: 16px;
                transform: rotate(90deg) translate(40%, 0%);
                transform-origin: 100% 100%;
            }
        }
    }

    .checkboxes {
        display: flex;
        justify-content: center;

        .ant-checkbox-wrapper {
            color: #caccd0;

            &:hover {
                .ant-checkbox-inner {
                    border-color: #b0b0b0;
                }

                span {
                    color: #0b1022;
                }
            }

            .ant-checkbox-input:focus + .ant-checkbox-inner {
                border-color: transparent;
            }

            &.ant-checkbox-wrapper-checked {
                color: #0b1022;
            }

            &.bet > .ant-checkbox-checked > .ant-checkbox-inner {
                background-color: $firstChartColor;
                border-color: $firstChartColor;
            }

            &.win > .ant-checkbox-checked > .ant-checkbox-inner {
                background-color: $secondChartColor;
                border-color: $secondChartColor;
            }

            &.net > .ant-checkbox-checked > .ant-checkbox-inner {
                background-color: $thirdChartColor;
                border-color: $thirdChartColor;
            }

            &.spins > .ant-checkbox-checked > .ant-checkbox-inner {
                background-color: $fourthChartColor;
                border-color: $fourthChartColor;
            }

            &.rtp > .ant-checkbox-checked > .ant-checkbox-inner {
                background-color: $fifthChartColor;
                border-color: $fifthChartColor;
            }

            &.newPlayers > .ant-checkbox-checked > .ant-checkbox-inner {
                background-color: $sixthChartColor;
                border-color: $sixthChartColor;
            }

            &.players > .ant-checkbox-checked > .ant-checkbox-inner {
                background-color: $seventhChartColor;
                border-color: $seventhChartColor;
            }
        }
    }

    .ant-checkbox {
        .ant-checkbox-inner {
            border-radius: 8px;
            background-color: #caccd0;
        }

        &.ant-checkbox-checked {
            .ant-checkbox-inner {
                &::after {
                    display: none;
                }
            }
        }
    }

    .slider {
        position: absolute;
        top: -33px;
        left: 50%;
        height: 40px;
        padding: 0 10px;
        border-radius: 20px;
        border-color: transparent;
        background-color: $grey;
        transform: translateX(-50%);
        span {
            padding: 0 10px;
            line-height: 40px;
        }
        .anticon {
            cursor: pointer;
        }
        .disabled {
            color: #caccd0;
            cursor: default;
        }
    }

    .selectors {
        position: absolute;
        top: -36px;
        right: 70px;
        width: 150px;

        .ant-select-selector {
            height: 40px;
            border-radius: 20px;
            border-color: transparent;
            background-color: $grey;

            .ant-select-selection-item {
                margin-left: 5px;
                line-height: 40px;
                color: #0b1022;
            }
        }
    }

    .menu {
        z-index: 1;
        position: absolute;
        top: -36px;
        right: 27px;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        text-align: center;
        line-height: 40px;
        &:hover {
            background-color: $grey;
        }
        &-popup {
            position: absolute;
            right: 0;
            width: 150px;
            border-radius: 10px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
            background: $white;
            color: rgba(0, 0, 0, 0.65);
            text-align: left;
            line-height: 1.5;
        }
        &-title {
            padding: 10px 12px 0;
            color: #aaaeb7;
            font-size: 11px;
            font-weight: bold;
            text-transform: uppercase;
        }
        &-items {
            & > div {
                padding: 10px 12px;
                &:not(.disabled):hover,
                &:not(.disabled).active {
                    cursor: pointer;
                    background-color: #e6f7ff;
                    color: black;
                }
                &.disabled {
                    cursor: not-allowed;
                    color: rgba(0, 0, 0, 0.25);
                }
            }
        }
    }
    .game-data-popup {
        z-index: 5;
        position: fixed;
        top: 50%;
        left: 50%;
        padding: 10px 20px;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        background: $white;
        .title {
            margin-bottom: 8px;
            font-size: 17px;
            font-weight: bold;
        }
        .items {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .item {
                margin: 10px 0;
            }
        }
        .row {
            min-width: 150px;
            padding: 0 2px;
        }
        .dates,
        .item-heading {
            color: $primary300;
            font-size: 10px;
        }
        span:not(.anticon) {
            color: $primary300;
        }
        i,
        span.anticon {
            position: absolute;
            top: 17px;
            right: 20px;
            cursor: pointer;
            font-size: 12px;
        }
    }

    .switcher__wrapper {
        position: absolute;
        top: -26px;
        left: 50%;
        width: 228px;
        height: 36px;
        border: 2px solid #eaedf2;
        transform: translateX(-50%);
    }
    .switcher__scrolled-block {
        background-color: white;
    }

    .switcher__option.active {
        color: #cf0920;
    }

    &.brokenDown {
        .checkboxes {
            .ant-checkbox-wrapper {
                &.currency1 > .ant-checkbox-checked > .ant-checkbox-inner {
                    background-color: $firstChartColor;
                    border-color: $firstChartColor;
                }
                &.currency2 > .ant-checkbox-checked > .ant-checkbox-inner {
                    background-color: $secondChartColor;
                    border-color: $secondChartColor;
                }
                &.currency3 > .ant-checkbox-checked > .ant-checkbox-inner {
                    background-color: $thirdChartColor;
                    border-color: $thirdChartColor;
                }
                &.currency4 > .ant-checkbox-checked > .ant-checkbox-inner {
                    background-color: $fourthChartColor;
                    border-color: $fourthChartColor;
                }
                &.currency5 > .ant-checkbox-checked > .ant-checkbox-inner {
                    background-color: $fifthChartColor;
                    border-color: $fifthChartColor;
                }
                &.currency6 > .ant-checkbox-checked > .ant-checkbox-inner {
                    background-color: $sixthChartColor;
                    border-color: $sixthChartColor;
                }
                &.currency7 > .ant-checkbox-checked > .ant-checkbox-inner {
                    background-color: $seventhChartColor;
                    border-color: $seventhChartColor;
                }
            }
        }
    }
}

.chart-tooltip {
    .title {
        margin-bottom: 10px;
        font-weight: bold;
    }
    .item {
        width: 100%;
        & > span {
            display: inline-block;
        }
        .item-name,
        .item-value {
            display: inline-block;
        }
    }
}

.echarts-for-react {
    height: 280px !important;
    min-width: 300px;
    margin: 0;
}

.mobile-chart {
    .echarts-for-react {
        height: 350px !important;
    }
    .checkboxes {
        min-height: 30px;
    }
}

@import 'ChartMedia';
