@import '../../utils/stylesVariables';

.ant-calendar-header {
  a:hover {
    color: #CF0920;
  }
}

.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year {
  background-color: black;
}

.ant-picker-range.custom-calendar {
  position: relative;
  width: 260px !important;
  padding-left: 30px;
  border: 0;
  border-radius: 20px;
  .ant-picker-input {
    width: 85px;
    overflow: hidden;
    input {
      font-size: 14px;
      font-weight: 500;
      color: $primary600;
    }
  }
  .ant-picker-suffix {
    position: absolute;
    left: 5px !important;
    right: auto;
    font-size: 16px;
    color: $primary600;
  }
  .ant-picker-clear {
    display: none;
  }
}

.ant-picker-dropdown.custom-calendar__dropdown {
  .ant-picker-panel-container {
    position: relative;
    width: 750px;
    padding: 20px 20px 95px;
    .ant-picker-panels {
      position: absolute;
      right: 20px;
      width: 560px;
    }
    .ant-picker-footer {
      width: 180px;
      min-width: 0;
      padding: 0;
      border: 0;
    }
    .ant-picker-ranges {
      padding: 0;
    }
    .ant-picker-ok {
      position: absolute;
      right: 25px;
      bottom: 15px;
      button {
        height: 40px;
        padding: 8px 25px;
        border: 0;
        border-radius: 20px;
        background-color: #cf0920;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .ant-picker-preset {
      width: 100%;
      .ant-tag-blue {
        margin: 0;
        padding: 0;
        border: 0;
        line-height: 30px;
        background-color: white;
        color: grey;
      }
      &:first-child {
        pointer-events: none;
        .ant-tag-blue {
          font-size: 16px;
          font-weight: 600;
        }
      }
      &:not(:first-child) {
        .ant-tag-blue {
          padding: 5px 15px;
          border-radius: 25px;
          font-size: 10px;
          text-transform: uppercase;
          cursor: pointer;
          &:hover {
            color: white;
            background-color: black;
          }
        }
      }
    }
    .ant-picker-cell {
      padding: 4px 0;
    }
    .ant-picker-cell-inner {
      width: 36px;
      height: 36px;
      padding: 7px;
      border: 0;
      border-radius: 20px;
      &:active {
        background-color: black !important;
      }
      &:hover {
        background-color: $grey !important;
      }
    }

    .ant-picker-cell-in-view {
      &.ant-picker-cell-in-range.ant-picker-cell-range-hover-start,
      &.ant-picker-cell-in-range.ant-picker-cell-range-hover-end {
        .ant-picker-cell-inner::after {
          border-radius: 20px !important;
          background-color: $grey !important;
        }
      }
      &.ant-picker-cell-range-start, &.ant-picker-cell-range-end, &.ant-picker-cell-selected {
        .ant-picker-cell-inner {
          border-radius: 20px !important;
          background-color: #000 !important;
          color: $white !important;
        }
      }
      &.ant-picker-cell-in-range, &.ant-picker-cell-in-range:hover {
        .ant-picker-cell-inner {
          border-radius: 0 !important;
          background-color: $grey !important;
          color: rgba(0, 0, 0, 0.65) !important;
        }
      }
      &.ant-picker-cell-today {
        .ant-picker-cell-inner {
          color: #000;
          font-weight: bold;
          &::before {
            border: 0;
          }
        }
      }
    }
  }
  &.withTime {
    .ant-picker-panel-container {
      width: 610px;
      padding: 20px 20px 115px;
      .ant-picker-panels {
        width: 410px;
      }
    }
  }
}