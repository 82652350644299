$primary: #0B1022;
$accent: #CF0920;
$primary600: #60636F;
$primary300: #AAAEB7;
$white: #fff;
$red: #d60a22;
$lighterRed: #d64456;
$grey: #f0f3f8;
$darkenGray: #B0B0B0;
$green: #05c46b;

