@import '../../utils/stylesVariables';

$accent-color: #0B1022;;
$accent-red-color: #d60a22;
$placeholder-color: #bec0c4;

.login-form {
  &__title {
    margin-bottom: 22px;
    font-size: 41px;
    font-weight: bold;
    color: $accent-color;
  }
  &__input {
    margin-bottom: 8px;
  }
  &__checkbox-container {
    margin-top: 26px;
    margin-bottom: 26px;
  }
  &__buttons-wrapper {
    display: flex;
    margin-top: 30px;
    align-items: center;
    justify-content: space-between;
  }
  &__forgot-link {
    display: block;
    width: fit-content;
    margin: 26px auto 0;
    color: $placeholder-color;
    font-weight: bold;
  }
  &__error-message {
    color: $accent-red-color;
    font-size: 13px;
    font-weight: bold;
    transition: .3s;
    margin-left: 20px !important;
    &.visible {
      margin-top: 0;
      position: relative;
      visibility: visible;
    }
  }
  &__newPass-message-wrapper {
    padding: 7px 15px 15px 15px;
    display: flex;
    align-items: center;
    color: #7a7a7a;
    span {
      margin-right: 8px;
      svg {
        color: #7a7a7a;
      }
    }
    .login-form__newPass-message {
      margin: 0;
    }
  }
  p {
    margin: 0 0 10px;
  }
  svg {
    font-size: 25px;
    color: $accent-color;
  }
  .ant-btn-primary:hover {
    box-shadow: 3px 4px 5px 1px rgba(0, 0, 0, 0.28);
  }
}

.input-container {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 24px;
  margin-bottom: 8px;
  &__icon {
    position: absolute;
    margin-left: 15px;
    display: flex;
    height: 48px;
    align-items: center;
    z-index: 1;
    &__right {
      display: flex;
      align-items: center;
      height: 48px;
      position: absolute;
      right: 8px;
      margin-left: 10px;
      padding-right: 10px;
      cursor: pointer;
      svg {
        color: $placeholder-color;
      }
    }
  }
  &__input {
    flex-grow: 1;
    padding-left: 48px;
    height: 48px;
    border: 0;
    border-radius: 24px;
    font-weight: bold;
    transition: .3s;

    &::placeholder {
      color: $placeholder-color;
    }

    &.error {
      border: 2px solid $accent-red-color;
    }
  }

}
.checkbox-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-content: center;
  width: fit-content;
  position: relative;
  padding-left: 18px;
  cursor: pointer;
  user-select: none;
  color: $placeholder-color;
  font-weight: bold;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%!important;
    width: 100% !important;
  }
  &__text {
    transition: .3s;
  }
  .checkmark {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border: 2px solid $placeholder-color;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s;
    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }
  &:hover input ~ .checkmark {
    background-color: white;
  }
  & input:checked ~ .checkmark {
    background-color: $accent-color;
    border: 2px solid $accent-color;
  }
  & input:checked ~ .checkbox-container__text {
    color: $accent-color;
  }
  & input:checked ~ .checkmark:after {
    display: block;
  }
  & .checkmark:after {
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@import 'LoginFormMedia';