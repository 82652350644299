@import '../../utils/stylesVariables';

.breadcrumbs {
    div {
        margin-right: 5px;
        cursor: default;
    }
    a {
        color: $primary300;
        margin-right: 5px;
        transition: 0.3s;
        &:hover {
            color: #cf0920;
            text-decoration: underline;
        }
    }
    p {
        margin: 0;
    }
}

@import 'BreadcrumbComponentMedia';
