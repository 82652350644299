@import '../../utils/stylesVariables';

.main-page {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    background-color: #f0f3f8;
    &.clear-overflow {
        overflow: initial;
    }
}

.wrapper {
    width: 100%;
    height: 100vh;
}

#gameHistory {
    z-index: 10001;
    position: fixed;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
    background-color: white;
    .closeBtn {
        position: absolute;
        top: 10px;
        right: 15px;
        margin-right: 5px;
        cursor: pointer;
        float: left;
        font-size: 40px;
        font-weight: bold;
    }
    iframe {
        width: 100%;
        height: 100%;
        border: 1px solid grey;
    }
}

iframe {
    opacity: 0;
    &.ready {
        opacity: 1;
    }
}

.content-header {
    z-index: 2;
    background-color: $grey;
    position: sticky;
    top: 56px;
    margin-bottom: 8px;
}

.casino_performance-container {
    position: relative;
    display: inline-block;
}

.casino_performance-table-title {
    margin-top: 3px;
    margin-bottom: -35px;
    font-weight: bold;
    width: fit-content;
    position: relative;
    .content-wrapper {
        width: fit-content;
    }
}

.table-title {
    &__span {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        span {
            color: $darkenGray;
        }
    }
}
