@media only screen
and (max-width : 767px) {
  .table-tools .header-line {
    flex-direction: column;
    .tools {
      width: 100%;
      justify-content: flex-end;
      i {
        margin-right: 6px;
      }
      .time-aggregator {
        margin-right: 6px;
      }
      #filter-button {
        margin-left: 6px;
      }
      .ant-calendar-picker {
        width: 230px!important;
      }
    }
  }
}

@media only screen
and (min-width : 768px)
and (max-width : 1100px) {
  .table-tools .header-line {
    flex-direction: column;
    .tools {
      width: 100%;
      justify-content: flex-end;
      i {
        margin-right: 6px;
      }
      .time-aggregator {
        margin-right: 6px;
      }
      #filter-button {
        margin-left: 6px;
      }
      .ant-calendar-picker {
        width: 230px!important;
      }
    }
  }
}