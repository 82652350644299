@import '../../utils/stylesVariables';

.login-page {
  display: flex;
  height: 100vh;
  min-height: 375px;
  width: 100%;
  &__container {
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__red {
      background-color: #c8091b;
      background-image: url('../../images/welcome-bg-bo-min.png');
      background-size: cover;
      background-position: center center;
      color: white;
    }
    &__gray {
      background-color: $grey;
    }
  }
  &__logo-container {
    width: 232px;
    height: 336px;
    background-image: url('../../images/BO-logo.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
  &__copyright {
    position: absolute;
    bottom: 20px;
    font-size: 20px;
  }
  &__form {
    width: 350px;
  }
  #button_login {
    width: 100%;
    height: 48px;
    background-color: $red;
    border-color: #cf0a20;
    font-weight: 600;
    &:focus {
      box-shadow: 2px 3px 4px 1px #7a7a7a;
    }
  }
}

.login-page-loader {
  display: none;
  z-index: 2;
  position: absolute;
  width: 100%;
  min-height: 375px;
  height: 100%;
  color: #ff000073;
  background-color: $grey;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: .92;
  &.visible {
    display: flex;
  }
  .loading {
    font-size: 2.5em;
  }
}

@import 'LoginComponentMedia';

