@media only screen
and (max-width : 320px) {

}

@media only screen
and (min-width : 321px) {

}

@media only screen
and (max-width : 768px)
and (orientation: landscape){

}

@media only screen
and (min-width : 768px)
and (max-width : 1024px) {
  .mobile-grand-total {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen
and (min-width : 768px)
and (max-width : 1024px)
and (orientation: landscape){

}

@media only screen
and (min-width : 1025px) {
  .mobile-grand-total {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen
and (min-width : 1824px) {
  .mobile-grand-total {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}