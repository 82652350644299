@import '../../utils/stylesVariables';

.search {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  &__input {
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 14px;
    align-items: center;
    border-radius: 10px;
    border: 0;
    padding-left: 56px;
    padding-right: 62px;
    transition: .3s;
    color: $primary600;
    font-weight: 500;
    &::placeholder {
      color: $primary300;
      letter-spacing: .5px;
      font-weight: 500;
    }
    &:hover {
      box-shadow: 0 2px 4px 0 $primary300;
    }
    &:focus {
      box-shadow: 0 2px 4px 0 $primary300;
    }
  }

  .search-icon {
    display: flex;
    justify-content: center;
    position: absolute;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    font-size: 23px;
    color: $primary600;
    cursor: pointer;
    border-radius: 20px;
    transition: .3s;
    &:hover {
      background-color: $grey;
    }
    &:hover + .search__input {
      box-shadow: 0 2px 4px 0 $primary300;
    }
    &__left {
      margin-left: 10px;
    }
    &__right {
      display: none;
      right: 10px;
      font-size: 20px;
      color: rgba(0,0,0,0.65);
      &.visible {
        display: flex;
      }
    }
  }
}