@media only screen
and (min-width : 768px)
and (max-width : 1024px) {
  .grand-total {
    .total-cell {
      &__title {
        font-size: 11px;
        line-height: 11px;
      }
      &__content {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
}

@media only screen
and (min-width : 1025px) {

}