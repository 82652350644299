.reports-plate {
  display: flex;
  height: 112px;
  width: 260px;
  margin-bottom: 10px;
  align-items: flex-end;
  border-radius: 10px;
  background-color: #FFFFFF;
  padding: 18px;
  cursor: pointer;
  svg {
    font-size: 40px;
    color: #E6E6E8;
  }
  &__title {
    width: 60%;
    color: #0B1022;
    margin: 0;
    margin-left: 10px;
    line-height: 20px;
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0.33px;
  }
  &.disabled {
    background-color: #ffffff7a;
    cursor: not-allowed;
    .reports-plate__title {
      color: #e2e2e2;
    }
  }
}