@import '../../utils/stylesVariables';

.stepper {
    margin-top: 16px;
    .strip {
        position: relative;
        border-radius: 12px;
        overflow: hidden;
    }
    .grey {
        width: 100%;
        height: 24px;
        background-color: $grey;
    }
    .green {
        top: 0;
        position: absolute;
        width: 50%;
        height: 24px;
        background-color: $accent;
    }
    .steps {
        top: -24px;
        position: relative;
        width: 100%;
        .step {
            display: inline-block;
            vertical-align: top;
            text-align: center;
            &.before {
                .name {
                    color: $primary300;
                }
            }
            &.current {
                .name {
                    color: $accent;
                }
            }
            &.after {
                .index {
                    border: 1px solid $grey;
                    color: $primary;
                }
            }
            .index {
                height: 24px;
                width: 24px;
                display: inline-block;
                border-radius: 12px;
                border: 1px solid $accent;
                color: $accent;
                background-color: $white;
                line-height: 22px;
                font-size: 12px;
            }
            .name {
                margin-top: 16px;
            }
        }
    }
}
