@media only screen
and (max-width : 320px) {
  .app-table {
    &__cell {
      font-size: 10px;
    }
    &__row {
      height: 30px;
    }
  }
}

@media only screen
and (min-width : 321px) {
  .app-table {
    &__row {
      height: 30px;
    }
  }
}

@media only screen
and (max-width : 768px)
and (orientation: landscape){

}

@media only screen
and (min-width : 768px)
and (max-width : 1024px) {
  .app-table {
    &__cell {
      font-size: 12px;
    }
    &__row {
      height: 40px;
    }
  }
}

@media only screen
and (min-width : 768px)
and (max-width : 1024px)
and (orientation: landscape){

}

@media only screen
and (min-width : 1025px) {
  .app-table {
    &__cell {
      font-size: 14px;
    }
    &__row {
      height: 40px;
    }
  }
}

@media only screen
and (min-width : 1824px) {

}