.promotion_data  {
  margin: 10px 0 20px;
  display: flex;
  flex-direction: column;
  span:first-child {
    width: 120px;
    display: inline-block;
  }
}

.campaign-status-column .marked-campaign {
  display: flex;
  background-color: #ffff00b0;
  margin: -12px -16px;
  height: 69px;
  width: calc(100% + 32px);
  text-indent: 16px;
  align-items: center;
}