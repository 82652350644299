.promotions-header-tools {
    padding: 8px 0;
}

.cursor-pointer-row {
    cursor: pointer;
}

.new-campaign-form-item {
    display: flex;
    margin-bottom: 8px;
    &__field {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    &__label {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 32px;
        width: 205px;
        margin-right: 16px;
    }
    &__error {
        color: red;
    }
    &__with-upload {
        display: flex;
        align-items: center;
        .ant-switch {
            margin-left: 8px;
            margin-right: 8px;
        }
        .ant-upload-list {
            position: absolute;
        }
    }
    .ant-input,
    .ant-select-selector {
        flex: 1;
        width: 100%;
    }

    .ant-select-multiple {
        width: 100%;
    }
}

.new-campaign-form-total {
    margin-top: 26px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-top: 1px solid #f0f0f0;
}

.new-campaign-form-total__footer {
    border-top: 1px solid #f0f0f0;
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
}

.new-campaign-form-total__footer > :last-child {
    margin-left: 8px;
}

.new-campaign_form-side {
    flex: 1;
    padding-right: 1rem;
    padding-bottom: 30px;
}

.promotion {
    &-header {
        &__buttons {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            button {
                margin-right: 8px;
            }
            margin-bottom: 8px;
        }
    }
    &-table {
        display: flex;
        &__side {
            width: 50%;
        }
    }
    &-table-item {
        display: flex;
        width: 100%;
        margin-bottom: 3px;
        &__label {
            width: 100px;
            min-width: 100px;
            margin-right: 8px;
        }
    }
    &__edit-button-wrapper {
        margin-bottom: 16px;
    }
}

.campaign-player-status-selector.ant-select > .ant-select-selector {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.campaign-empty {
    margin-top: 16px;
    height: 100%;
}

.disable-campaign-red-text {
    color: red;
    margin: 0;
    text-decoration: underline;
    font-size: 12px;
    margin-right: 10px;
    cursor: pointer;
}

.disable-campaign-red-text__wrapper {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.new-campaign__modal {
    .ant-modal-body {
        max-height: 70vh;
        overflow: auto;
    }
}
