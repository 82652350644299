@import '../../utils/stylesVariables';

.app-table {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    &__wrapper {
        overflow-x: auto;
    }
    &__title {
        margin: 0;
        padding: 10px 20px 0;
        line-height: 38px;
        font-weight: bold;
        position: sticky;
        left: 0;
    }
    &__row {
        &__clickable {
            cursor: pointer;
            transition: 0.3s;
            &:hover {
                background-color: #f0f3f8;
            }
            &:hover > .sticky {
                background-color: #f0f3f8;
            }
            &:hover .login {
                color: #cf0920;
                text-decoration: underline;
            }
            &:hover .callback-arrow {
                color: #cf0920;
            }
        }
        &.danger {
            background-color: #ffebef;
        }
    }
    &__cell {
        position: relative;
        text-align: center;
        padding: 5px 16px;
        color: $primary;
        font-weight: 500;
        border-bottom: 1px solid $grey;
        .shaped-roundId {
            width: 130px;
            display: block;
            word-break: break-all;
        }
        a {
            color: $primary;
            &:hover {
                text-decoration: underline;
            }
            i,
            span.anticon {
                transition: all 1s;
                transform-origin: 50% 50%;
                &:hover {
                    transform: scale(1.2);
                }
            }
        }
        &:last-child {
            padding-right: 20px;
        }

        .account-status {
            padding: 4px 12px;
            border-radius: 15px;
            text-transform: uppercase;
            color: #2eb165;
            background-color: #d5f5e3;
            font-size: 12px;
            font-weight: bold;
            &.blocked {
                color: #d5263a;
                background-color: #fae6e8;
            }
        }
        .copy-icon {
            position: absolute;
            padding: 4px 6px;
            font-size: 14px !important;
            opacity: 0;
        }
        &:hover {
            .copy-icon {
                opacity: 0.5;
            }
        }
    }
    &__currency {
        text-align: right;
        color: $primary300;
    }
    &__span {
        display: block;
        &.alignment-right {
            text-align: right;
        }
        &.alignment-left {
            text-align: left;
        }
        &.alignment-center {
            text-align: center;
        }
    }
    &__column-title {
        vertical-align: top;
        height: 52px;
        background-color: white;
        font-weight: bold;
        &:first-of-type {
            border-top-left-radius: 10px;
        }
        &:last-of-type {
            border-top-right-radius: 10px;
        }
        &:hover .sorter {
            opacity: 1;
        }
        .app-table__span {
            padding-top: 5px;
            & span {
                white-space: nowrap;
            }
        }
    }
    .index-column {
        width: 50px;
    }
    i,
    span.anticon {
        font-size: 10px;
    }

    .gmtOffset {
        color: $primary300;
    }

    &__column-filters {
        .switcher {
            margin-bottom: 16px;
            &__outer {
                display: flex;
                justify-content: space-between;
                width: 100%;
                p {
                    margin: 0;
                }
            }
        }
        .ant-modal-body {
            height: 400px;
            overflow: auto;
            display: flex;
            flex-direction: column;
        }
    }
}

.sticky {
    z-index: 1;
    transition: 0.3s;
    position: relative;
    background-color: white;
    &-title {
        z-index: 3;
        left: 0;
    }
}

.cell_wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    &.alignment-right {
        justify-content: flex-end;
    }
    &.alignment-left {
        justify-content: flex-start;
    }
    &.alignment-center {
        justify-content: center;
    }
}

.cell-flex-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sorter {
    opacity: 0;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 9px;
    transition: 0.3s;
    cursor: pointer;
    &.desc {
        span:first-child {
            color: #cf0920;
        }
    }
    &.asc {
        span:nth-child(2) {
            color: #cf0920;
        }
    }
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
    -webkit-box-shadow: 0 0 0 2px rgba(252, 67, 84, 0.2);
    box-shadow: 0 0 0 2px #87d7e3;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f7ff;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f7ff;
}

.error {
    font-weight: 500;
    color: #cf0920;
}

.dot {
    width: 7px;
    height: 7px;
    margin: 2px 10px;
    display: inline-block;
    border-radius: 4px;
    &.red {
        background-color: #f5222d;
    }
    &.green {
        background-color: #52c41a;
    }
}

i,
span.anticon {
    &.actions {
        margin-top: 3px;
        font-size: 20px;
        cursor: pointer;
    }
    &.device-type {
        margin-top: 4px;
        font-size: 20px;
    }
    &.anticon-info-circle {
        font-size: 20px;
    }
}

.grey {
    background-color: #ebeef3;
}
.ant-result-extra {
    color: rgba(0, 0, 0, 0.45) !important;
    margin-top: 0 !important;
}

.table-daterange {
    margin: 0;
    &__span {
        font-size: 13px;
        color: $primary600;
        margin-left: 4px;
        .anticon {
            margin-right: 4px;
        }
    }
}

.columns-filter-button {
    position: absolute;
    top: -25px;
    right: 25px;
    cursor: pointer;
    &:hover {
        color: $accent;
        text-decoration: underline;
    }
}

.ant-table-row.blocked {
    color: #b0b0b0e0;
}

@import 'CustomTableMedia';
