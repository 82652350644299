@media only screen
and (max-width : 320px) {
  .login-form{
    &__title {
      font-size: 28px;
      margin-bottom: 10px;
    }
    &__checkbox-container {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &__forgot-link {
      margin-top: 16px;
    }
  }
}

@media only screen
and (max-width : 320px)
and (orientation: landscape){
  .login-form{
    &__title {
      font-size: 25px;
      margin-bottom: 8px;
    }
    &__checkbox-container {
      margin-top: 13px;
      margin-bottom: 13px;
    }
    &__forgot-link {
      margin-top: 13px;
    }
  }
}

@media only screen
and (min-width : 321px) {
  .login-form{
    &__title {
      font-size: 28px;
      margin-bottom: 10px;
    }
    &__checkbox-container {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &__forgot-link {
      margin-top: 16px;
    }
  }
}

@media only screen
and (max-width : 768px)
and (orientation: landscape){
  .login-form{
    &__title {
      font-size: 24px;
      margin-bottom: 7px;
    }
    &__checkbox-container {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    &__forgot-link {
      margin-top: 13px;
    }
  }
}

@media only screen
and (min-width : 768px)
and (max-width : 1024px) {

}

@media only screen
and (min-width : 768px)
and (max-width : 1024px)
and (orientation: landscape){
  .login-form{
    &__title {
      font-size: 28px;
      margin-bottom: 10px;
    }
    &__checkbox-container {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &__forgot-link {
      margin-top: 16px;
    }
  }
}

@media only screen
and (min-width : 1025px) {
  .login-form{
    &__title {
      font-size: 41px;
      margin-bottom: 22px;
    }
    &__checkbox-container {
      margin-top: 26px;
      margin-bottom: 26px;
    }
    &__forgot-link {
      margin-top: 26px;
    }
  }
}

@media only screen
and (min-width : 1824px) {

}