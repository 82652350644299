@media only screen
and (min-width : 768px) {
  .search-result__wrapper {
    grid-template-columns: repeat(2, auto);
  }
}

@media only screen
and (min-width : 768px)
and (max-width : 1024px)
and (orientation: landscape){
  .search-result__wrapper {
    grid-template-columns: repeat(3, auto);
  }
}

@media only screen
and (min-width : 1025px) {
  .search-result__wrapper {
    grid-template-columns: repeat(3, auto);
  }
}

@media only screen
and (min-width : 1400px) {
  .search-result__wrapper {
    grid-template-columns: repeat(4, auto);
  }
}

@media only screen
and (min-width : 1824px) {
  .search-result__wrapper {
    grid-template-columns: repeat(5, auto);
  }
}