@media only screen
and (max-width : 320px) {
  .tab {
    height: calc(100% - 65px);
  }
}

@media only screen
and (min-width : 321px) {
  .tab {
    height: calc(100% - 65px);
  }
}

@media only screen
and (min-width : 1025px) {
  .tab {
    height: calc(100% - 64px);
  }
}