@import '../../utils/stylesVariables';

.add-operator {
    h2 {
        font-size: 20px;
        &.title {
            margin: 0 24px 30px;
            font-weight: bold;
            &.with-paddings {
                margin-top: 16px;
            }
        }
    }
    p {
        &.error {
            height: 21px;
            margin: 5px 0 0;
            color: $red;
        }
    }
}

.operator-step {
    position: relative;
    textarea {
        margin-top: 15px;
        background-color: $primary;
        color: $white;
    }
    table {
        width: 100%;
        tr {
            border-bottom: 1px solid $grey;
            th,
            td {
                max-width: 200px;
                padding: 8px 24px;
            }
        }
    }
    .item,
    .ant-form-item {
        margin: 30px 0 20px;
        display: flex;
        label {
            width: 120px;
            line-height: 40px;
        }
        input {
            width: 520px;
            height: 40px;
            margin-right: 20px;
        }
    }
    .currency-item {
        position: relative;
        margin: 20px 0;
        padding: 16px 24px;
        border-radius: 10px;
        background-color: $white;
        h3 {
            position: relative;
            margin: 0;
            cursor: pointer;
            font-weight: bold;
        }
        .currency-content {
            max-height: 1000000px;
            transition: max-height 0.1s ease-in-out;
            overflow: hidden;
            &.collapsed {
                max-height: 0;
            }
        }
        .arrow {
            position: absolute;
            top: 16px;
            right: 24px;
            i,
            span.anticon {
                font-size: 12px;
                svg {
                    transition: 0.3s ease-in;
                }
            }
        }
        .gray {
            color: $primary300;
        }
    }
    .error {
        height: 21px;
        color: $red;
    }
    .buttons {
        position: absolute;
        top: 0;
        right: 14px;
        .btn {
            height: 35px;
            margin: 15px 10px;
            line-height: 35px;
            color: $primary;
            background-color: $grey;
        }
        &.without-paddings {
            right: 0;
        }
    }
    .bottom-buttons {
        margin-left: 14px;
        .btn {
            margin: 30px 10px 10px;
        }
    }
    &.manage-games,
    &.manage-currencies {
        position: relative;
    }
    &.step-wrapper {
        padding: 15px 0;
        border-radius: 10px;
        background-color: $white;
    }
}

.cp-brand-item {
    .ant-form-item-label {
        text-align: left;
    }
    &__label {
        display: flex;
    }
    .ant-form-item-label {
        width: 220px;
    }
    .ant-form-item-control-wrapper {
        width: 520px;
    }
    .content-item__title {
        font-size: 16px;
    }
    &__remove-button {
        position: absolute;
        display: flex;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        right: 60px;
        top: -58px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            background-color: $grey;
        }
    }
}

.cp-brand__add-button-wrapper {
    .content-item {
        padding: 0;
        height: 56px;
        &__content {
            height: 100%;
            margin-top: 0;
        }
    }
    button {
        height: 100%;
        width: 100%;
        border: 0;
    }
}
