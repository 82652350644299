@import '../../utils/stylesVariables';

.usersList {
    .app-table__row.blocked span:not(.blocked) {
        color: #b4b6bc;
        svg {
            color: #0b1022;
        }
    }
    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
    }
    &__buttons {
        display: flex;
        align-items: center;
    }
}

.addUserBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    background-color: $red;
    color: #fff;
    span {
        margin-right: 15px;
        font-size: 24px;
    }
    &.disabled {
        background-color: $lighterRed;
        cursor: default;
    }
}

.actionsPopup {
    position: absolute;
    width: 200px;
    margin: 0;
    padding: 10px 0;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    list-style-type: none;
    background: white;
    li {
        padding: 10px 20px;
        cursor: pointer;
        font-weight: bold;
        &:hover {
            background-color: #e6f7ff;
        }
    }
    i,
    span.anticon {
        margin-right: 15px !important;
        font-size: 16px;
    }
}
