@media only screen
and (min-width : 768px)
and (max-width : 1024px) {
  .chart  {
    .switcher__wrapper {
      top: -2px;
      z-index: 1;
    }
    .selectors {
      top: -20px;
      z-index: 4;
    }
  }
}

@media only screen
and (min-width : 1025px) {

}