.billing-report-calendar-popup {
  .ant-calendar {
    width: 280px;
    .ant-calendar-input-wrap {
      display: none;
    }
    .ant-calendar-panel {
      padding: 0;
    }
    .ant-calendar-month-panel-next-year-btn {
      height: 40px;
    }
  }
}

.monthly-billing {
  .ant-calendar-picker {
    width: 125px!important;
    height: 40px;
  }
  .ant-calendar-picker-input {
    line-height: 40px;
    height: 40px;
    cursor: pointer;
  }
  .ant-select-selection--single {
    height: 40px;
    border-radius: 20px;
    border: 0;
  }
  .ant-select-selection__rendered {
    line-height: 40px;
  }
  .cp-header-buttons__button {
    .ant-select {
      margin-left: 8px;
    }

    .monthly-billing__selector {
      .ant-select-selector {
        height: 42px;
        line-height: 42px;
        display: flex;
        align-items: center;
        border: 0;
        border-radius: 20px;
      }
    }
  }
}