.overlay {
    visibility: hidden;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: #eaedf2cc;
    &.visible {
        visibility: visible;
        cursor: pointer;
        z-index: 1000;
    }
    &.transparent {
        background-color: transparent;
    }
}
