@media only screen
and (max-width : 320px) {

}

@media only screen
and (max-width : 767px) {
  .main-menu__logo.visible {
    margin-left: 28px;
  }
  .ant-menu-item {
    padding-left: 24px!important;
  }
}

@media only screen
and (min-width : 768px)
and (max-width : 1024px) {
  .main-menu__logo.visible {
    margin-left: 28px;
  }
  .ant-menu-item {
    padding-left: 24px!important;
  }
}

@media only screen
and (min-width : 1025px) {
  .main-menu__logo.visible {
    margin-left: 65px;
  }
  .ant-menu-submenu-title {
    .ant-menu-item {
      padding-left: 48px!important;
    }
  }

}

@media only screen
and (min-width : 1824px) {

}