@import 'src/utils/stylesVariables';

.brand {
  h2 {
    font-size: 20px;
    &.title {
      margin: 0 24px 30px;
      font-weight: bold;
      &.with-paddings {
        margin-top: 16px;
      }
    }
  }
}