@import '../../utils/stylesVariables';

.grand-total {
  display: flex;
  &__cell {
    flex-grow: 1;
  }
  .total-cell {
    &__title {
      color: $primary300;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.5px;
      margin: 0;
    }
    &__content {
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin: 0;
    }
    &__currency {
      color: $primary300;
    }
  }
}

@import "TotalLineMedia";