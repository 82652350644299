@import '../utils/stylesVariables';
$firstChartColor: #00d9a0;
$secondChartColor: #3579ff;
$thirdChartColor: #ffb600;
$firstChartBarColor: #003f5c;
$secondChartBarColor: #374c80;
$thirdChartBarColor: #7a5195;

.dashboard {
  width: 100%;
  display: block;
  overflow-y: scroll;
  font-size: 16px;
  h1 {
    margin: 0;
    padding: 20px 16px 10px;
    background-color: $white;
    text-transform: capitalize;
    font-weight: bold;
  }
  h2 {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  .content-item__title {
    padding-left: 16px;
  }
  &__item-wrapper {
    padding: 4px 8px;
    &:first-child {
      padding-top: 8px;
    }
  }
  &__item {
    position: relative;
    border-radius: 10px;
    background-color: $white;
    padding: 16px;
    &.mobile-chart {
      padding: 12px 0 0 0;
    }
  }
  .selected_key {
    pointer-events: none;
  }
  .selector {
    opacity: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .list {
    &_item {
      display: flex;
      & > div {
        margin-bottom: 4px;
      }
      .name {
        color: $primary300;
        white-space: nowrap;
        overflow: hidden;
      }
      .strip {
        min-width: 5px;
        height: 5px;
        margin: 10px 10px 9px;
        border-radius: 3px;
        background-color: $accent;
      }
      .value {
        white-space: nowrap;
      }
    }
  }
  .footer {
    padding: 20px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &_logo {
      width: 150px;
      height: 38px;
      background-size: cover;
      background-image: url('../images/logo.png');
    }
    &_logout {
      width: 100%;
      height: 48px;
      margin-top: 20px;
      color: $red;
      background-color: $white;
      border-color: white;
      text-align: center;
      cursor: pointer;
    }
  }
  .loader {
    height: 120px;
    .anticon {
      font-size: 75px;
      line-height: 120px;
    }
  }
  .ant-empty {
    height: auto;
  }

  .popup {
    font-size: 13px;
    &__value {
      z-index: 4;
      position: fixed;
      top: 50%;
      left: 50%;
      min-width: 140px;
      padding: 20px;
      border-radius: 10px;
      transform: translate(-50%, -50%);
      background-color: $white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      .title, span {
        color: $primary300;
      }
      .value {
        font-size: 17px;
      }
    }
    .anticon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
      color: $primary;
    }
  }
  .echarts-for-react {
    & > * {
      z-index: 0 !important;
    }
  }
}

.calendar {
  padding: 0;
  text-align: center;
  & > * {
    width: 100%;
    padding: 15px;
  }
  .selected_key {
    position: absolute;
    top: -1px;
    left: 0;
    .anticon {
      margin-right: 10px;
    }
  }
}

.mobile-grand-total {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  &__item {
    padding: 16px;
    background-color: white;
    border-radius: 10px;
  }
  .mobile-total-item {
    &__title {
      color: $primary300;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.5px;
      margin: 0;
    }
    &__content {
      font-size: 22px;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin: 0;
    }
    &__currency {
      margin-left: 5px;
      color: $primary300;
    }
  }
}

.mobile-total-item-full-value {
  background-color: red;
}

.top_games {
  .selected_key {
    z-index: 2;
    position: absolute;
    top: 16px;
    right: 35px;
    width: 115px;
    font-size: 14px;
    font-weight: normal;
    color: $primary300;
    text-align: right;
  }
  .selector {
    z-index: 1;
    position: absolute;
    top: 10px;
    width: calc(100% - 32px);
    height: 40px;
    & + .anticon {
      position: absolute;
      top: 20px;
      right: 15px;
      font-size: 14px;
      color: $primary300;
    }
  }
}

.mobile-chart-tooltip {
  display: flex;
  margin-top: -10px;
  .tooltip-item {
    width: 33%;
    font-size: 14px;
    letter-spacing: .5px;
    &__content {
      color: #0B1022;
      margin: 0;
    }
    &__currency {
      margin-left: 3px;
    }
    &__symbol {
      display: block;
      height: 8px;
      width: 8px;
      margin-right: 5px;
      border-radius: 4px;
      background-color: red;
    }
  }
};

.mobile-checkboxes {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;

  .ant-checkbox-wrapper {
    color: rgba(11,16,34,0.15);
    width: 33%;
    margin: 0;

    &:hover {
      .ant-checkbox-inner {
        border-color: #b0b0b0;
      }
    }

    .ant-checkbox-inner {
      width: 8px;
      height: 8px;
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: transparent;
    }

    &.ant-checkbox-wrapper-checked {
      color: rgba(11,16,34,0.4);
    }

    &.bet > .ant-checkbox-checked > .ant-checkbox-inner {
      background-color: $firstChartColor;
      border-color: $firstChartColor;
    }

    &.win > .ant-checkbox-checked > .ant-checkbox-inner {
      background-color: $secondChartColor;
      border-color: $secondChartColor;
    }

    &.net > .ant-checkbox-checked > .ant-checkbox-inner {
      background-color: $thirdChartColor;
      border-color: $thirdChartColor;
    }
  }
}

.phone {
  &.landscape {
    .ant-result.ant-result-404 {
      transform: scale(0.65) translateY(-25%);
    }
  }
}


@import './commonMobileMedia';