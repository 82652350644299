@import '../../utils/stylesVariables';

$redTabColor: #CF0920;

.tab {
  height: 100%;
  &__buttons {
    margin: 0 20px;
    padding: 2px;
    display: inline-block;
    border-radius: 20px;
    background-color: $grey;
  }
  &__button {
    padding: 8px 25px;
    display: inline-block;
    border-radius: 18px;
    cursor: pointer;
    color: $primary300;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 21px;
    transition: all 0.3s;
    &:hover {
      color: $redTabColor;
    }
    &.active {
      color: $redTabColor;
      background-color: $white;
    }
  }
  &__content-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__content {
    display: none;
    font-size: 30px;
    &.visible {
      display: block;
    }
  }
  .content-item__wrapper.tabs {
    padding-bottom: 0;
    margin-bottom: -12px;
  }
}

@import 'TabMedia';