@import '../../utils/stylesVariables';

.content {
    display: flex;
    flex-direction: column;
    padding: {
        left: 24px;
        right: 24px;
    }
    width: 100%;
    &-title {
        margin: 0;
        color: #0b1022;
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 27px;
        .title-span {
            color: $primary300;
            font-size: 14px;
            letter-spacing: 0.5px;
            font-weight: 500;
        }
    }
    &.with-footer {
        padding-bottom: 0;
        flex: auto;
        & > div:nth-last-child(2) {
            margin-bottom: auto;
        }
    }
}

.content-item {
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 8px 16px;
    transition: max-height 0.1s ease-in-out;
    height: auto;
    max-height: 1000000px;
    overflow: hidden;
    &.collapsed {
        max-height: 53px;
        cursor: pointer;
        .content-item__content {
            opacity: 0;
        }
    }
    &__content {
        position: relative;
        transition: opacity 0.1s ease-in-out;
        opacity: 1;
    }
    &__wrapper {
        background-color: $grey;
        width: 100%;
        padding-bottom: 8px;
    }
    &__title {
        line-height: 21px;
        font-size: 14px;
        font-weight: bold;
        color: #0b1022;
        letter-spacing: 0.5px;
        margin: 0 0 8px;
    }
    &__collapsing-button {
        position: absolute;
        display: flex;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        right: 9px;
        top: 9px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            background-color: $grey;
        }
        i,
        span.anticon {
            font-size: 12px;
            svg {
                transition: 0.3s ease-in;
            }
        }
    }
}

.main-page.player-profile {
    height: 100vh;
}
main.players-report {
    padding: 0 16px 0;
    height: calc(100% - 64px);
    .content-header {
        position: relative;
    }
    .content-item__wrapper.player-profile {
        height: fit-content;
        max-height: calc(100% - 75px);
    }
    .app-table__wrapper.player-profile {
        height: calc(100% - 49px);
    }
    .content-item.player-profile {
        height: 100%;
        padding: 0;
    }
    .content-item__content.player-profile {
        height: inherit;
    }
}

@import 'ContentComponentMedia';
