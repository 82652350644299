@import '../../utils/stylesVariables';

.table-tools {
  display: block;
  width: 100%;

  .header-line {
    display: flex;
    justify-content: space-between;

    .ant-calendar-picker {
      cursor: pointer;
      font-size: 14px;
    }

    .ant-calendar-range-picker-input {
      cursor: pointer;
      color: $primary600;
    }
    .tools {
      display: flex;
      align-self: center;
    }
  }

  .filters {
    margin-bottom: 8px;
    flex-wrap: wrap;
    height: auto;
    align-items: center;
    .filtered-by {
      display: inline-block;
      padding-right: 1.5em;
      line-height: 38px;
      span {
        font-size: 14px;
        margin-bottom: 0;
        color: $primary300;
        font-weight: 500;
        letter-spacing: .5px;
      }
    }
    &.visible {
      display: flex;
    }
  }

  .label {
    background: red;

    &:after {
      content: '\A';
      white-space: pre;

    }

  }

  .break {
    width: 100%;
  }

  .ant-tag .anticon-close {
    font-weight: bold;
  }
}

.refresh-button {
  height: 40px;
  width: 48px;
  margin-right: 20px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #60636F;
  svg {
    height: 40%;
    width: 40%;
  }
}

@import "TableHeaderWrapperMedia";
