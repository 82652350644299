@import '../../utils/stylesVariables';

.settings-list {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
    background-color: white;
    &__item {
        padding: 20px;
        display: flex;
        flex-direction: column;
    }
    .settings-item {
        &__title {
            margin: 0 0 10px;
            color: $primary300;
            font-size: 12px;
            line-height: 13px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }
        &__inner {
            margin-top: 16px;
        }
        &__property {
            width: 200px;
            margin-bottom: 0;
            font-weight: 700;
        }
        &__value {
            margin-bottom: 0;
            &.name {
                margin-left: 20px;
            }
        }
    }

    .settings-inner {
        display: flex;
        align-items: center;
        &__title {
            width: 200px;
            margin: 0;
            color: #0b1022;
            font-weight: 700;
            span {
                margin-left: 5px;
            }
        }
        &__item {
            margin: 0;
            font-weight: 700;
            color: #bdbfc4;
        }
        .ant-select-selector,
        .custom_picker {
            width: 380px;
            height: 40px;
            display: flex;
            border: 2px solid #d9d9d9;
            border-radius: 4px;
            align-items: center;
            padding-left: 8px;
            padding-right: 16px;
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
        }
        .ant-select-focused,
        .ant-select:hover,
        .ant-select:focus,
        .ant-select:active {
            .ant-select-selector {
                border: 2px solid #d9d9d9 !important;
                box-shadow: none !important;
            }
        }
        &.date {
            .ant-select {
                text-transform: capitalize;
            }
        }
    }
    .switchbox-title,
    .ant-select {
        color: #0b1022;
        font-weight: 700;
    }
    .picker__wrapper .list {
        width: 380px;
        margin-left: 200px;
        border: 2px solid #d9d9d9;
        border-radius: 4px;
    }
    .ant-form-explain {
        max-width: 156px;
    }
    .differentPassInfo {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        color: $red;
    }
    .ant-select-arrow {
        pointer-events: none;
    }
}
